import { apiClient } from "@/lib/api-client";
import { throwJsonRouteError } from "@/utils/throwJsonRouteError";
import { authHeader } from "@admin-ui/utils/authHeader";
import { User } from "@admin-ui/providers/AuthProvider";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { getResponseJson } from "@/utils/getResponseJson";

export const signUpViaPaypal = async (
  authenticatedUser: User,
  registrationId: string | undefined,
) => {
  if (!registrationId)
    throwJsonRouteError(
      "Unable to sign up with paypal, missing registration id",
      400,
    );

  toast("Signing user up...");
  try {
    const response = await apiClient.v1.admin.registrations[":id"][
      "create-new-user"
    ].$post(
      {
        param: { id: registrationId },
      },
      authHeader(authenticatedUser.jwt),
    );

    const result = await getResponseJson(response);

    if (result.error) {
      toast(result.error.message.join(" "), { type: "error" });
    } else {
      toast("Successfully signed user up!", { type: "success" });
    }
    return null;
  } catch (e) {
    toast(e instanceof Error ? e.message : "Error when signing up user", {
      type: "error",
    });
    Sentry.captureException(e);
    return null;
  }
};
