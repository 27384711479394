import { DateTime } from "luxon";

export const parseDateTimeString = (dateString: string | null) => {
  if (dateString === null) return null;

  const parse = DateTime.fromISO(dateString, {
    zone: "utc",
  }).setZone("local");

  if (parse.isValid) return parse;

  throw new Error(`Unable to parse ${dateString}`);
};
