import { ReactNode, useMemo, useLayoutEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { debounce, useVirtualizer } from "@tanstack/react-virtual";
import { useOnWindowResize } from "@admin-ui/hooks/useOnWindowResize";
import { Header, Row, Table } from "@tanstack/react-table";

interface Props<T> {
  footer?: ReactNode;
  header?: (header: Header<T, unknown>) => ReactNode;
  row: (row: Row<T>) => ReactNode;
  rootClassName?: string;
  rowSizeEstimate: number;
  tableClassName?: string;
  table: Table<T>;
}

export const ScrollableTable = function ScrollableTable<T>({
  footer,
  header: renderHeader,
  row: renderRow,
  rootClassName,
  rowSizeEstimate,
  tableClassName,
  table,
}: Props<T>) {
  const [height, setHeight] = useState(0);

  const scrollRef = useRef<HTMLDivElement>(null);
  const headRef = useRef<HTMLTableSectionElement>(null);

  const virtualizer = useVirtualizer({
    count: table.getRowCount(),
    getScrollElement: () => scrollRef.current,
    estimateSize: () => rowSizeEstimate,
    overscan: 20,
  });

  const recordHeight = () => {
    const offset = scrollRef.current?.offsetTop ?? 0;
    const height = window.innerHeight - offset - 100;
    setHeight(height);
  };

  useLayoutEffect(recordHeight, []);
  useOnWindowResize(recordHeight);

  const snapHeaderToTop: (scrollTop: number) => void = useMemo(
    () =>
      debounce((scrollTop: number) => {
        const elem = headRef.current;
        if (!elem) return;

        elem.style.transform = `translateY(${scrollTop}px)`;
        elem.style.opacity = "1";
      }, 400),
    [],
  );

  return (
    <div
      className={twMerge("overflow-auto", rootClassName)}
      ref={scrollRef}
      onScroll={(event) => {
        headRef.current!.style.opacity = "0";
        snapHeaderToTop(event.currentTarget.scrollTop);
      }}
      style={{ height }}
    >
      <div style={{ height: `${virtualizer.getTotalSize()}px` }}>
        <table
          className={twMerge(
            "min-w-full text-sm border-l border-separate border-spacing-0 border-neutral-300",
            tableClassName,
          )}
        >
          <tbody className="z-0">
            {virtualizer.getVirtualItems().map((virtualRow, index) => {
              const row = table.getRowModel().rows[virtualRow.index];

              return (
                <tr
                  key={row?.id}
                  className="group"
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${
                      virtualRow.start - index * virtualRow.size
                    }px)`,
                  }}
                >
                  {row && renderRow(row)}
                </tr>
              );
            })}
          </tbody>
          {/*
            By placing thead after tbody, we mitigate any z-index issues when
            the header is translated vertically over the table body
            */}
          {renderHeader && (
            <thead ref={headRef}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => renderHeader(header))}
                </tr>
              ))}
            </thead>
          )}
          {footer && (
            <tfoot className="sticky" style={{ insetBlockEnd: 0 }}>
              {footer}
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
};
