import { FC, memo, useMemo, useState } from "react";
import { useCustomersLoader } from "../loaders/customersLoader";
import { Input } from "@/components";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
} from "@tanstack/react-table";
import { ScrollableTable, Td, Th } from "@admin-ui/components/tables";
import { Outlet } from "react-router-dom";
import Fuse from "fuse.js";
import { useDebouncedState } from "@/hooks/useDebouncedState";
import { BlueLink } from "@admin-ui/components/BlueLink";
import { DateTime } from "luxon";
import { FuzzySearch } from "@admin-ui/components/FuzzySearch";
import { useDownloadCsv } from "@admin-ui/hooks/useDownloadCsv";
import { DownloadCsvButton } from "@admin-ui/components/DownloadCsvButton";

type Customer = ReturnType<typeof useCustomersLoader>[number];

const columnHelper = createColumnHelper<Customer>();

const columns = [
  columnHelper.accessor("userEmail", {
    filterFn: "includesString",
    header: "User Email",
  }),
  columnHelper.accessor("name", {
    filterFn: "includesString",
    header: "Name",
    cell: ({ row, getValue }) => {
      return <BlueLink to={row.original.userId}>{getValue()}</BlueLink>;
    },
  }),
  columnHelper.accessor("joined", {
    enableColumnFilter: false,
    header: "Joined",
    cell: ({ getValue }) => getValue().toLocaleString(DateTime.DATETIME_SHORT),
  }),
  columnHelper.accessor("latestSubscriptionStatus", {
    header: "Latest Subscription Status",
    cell: ({ getValue }) => {
      const val = getValue();
      if (val === null) {
        return "NA";
      } else {
        // Capitalize the first letter of the string
        return val.charAt(0).toUpperCase() + val.slice(1);
      }
    },
  }),
  columnHelper.accessor("latestPlanName", {
    header: "Latest Plan",
    cell: ({ getValue }) => getValue(),
  }),
  columnHelper.accessor("country", {
    header: "Country",
    filterFn: "includesString",
  }),
];

export const Customers: FC = memo(() => {
  const customers = useCustomersLoader();
  const [sorting, setSorting] = useState<SortingState>([]);

  const {
    state: fuzzySearch,
    debounced: debouncedFuzzySearch,
    setState: setFuzzySearch,
  } = useDebouncedState("");

  const fuse = useMemo(
    () =>
      new Fuse(customers, {
        keys: ["userEmail", "name"],
        findAllMatches: true,
      }),
    [customers],
  );

  const fuzzySearchedItems = useMemo(() => {
    return fuse.search(debouncedFuzzySearch).map((result) => result.item);
  }, [fuse, debouncedFuzzySearch]);

  const data =
    debouncedFuzzySearch.trim() === "" ? customers : fuzzySearchedItems;

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const downloadCsv = useDownloadCsv(
    table.getRowModel().rows,
    table.getFlatHeaders(),
    [
      { rowKey: "userId", label: "ID" },
      { columnId: "userEmail" },
      { columnId: "name" },
      { columnId: "joined" },
      { columnId: "latestSubscriptionStatus" },
      { columnId: "latestPlanName" },
      { columnId: "country" },
      { rowKey: "field", label: "Field" },
      { rowKey: "isStudent", label: "Student" },
    ],
  );

  return (
    <div className="flex flex-col flex-grow relative">
      <div className="p-4">
        <h1 className="font-semibold text-lg flex flex-row items-center">
          Customers
          <DownloadCsvButton
            onClick={() => downloadCsv(`customers-${DateTime.now().toISO()}`)}
          />
        </h1>
        <FuzzySearch
          enableHint={sorting.length === 0}
          label="Search user email and name with fuzzy search"
          onChange={setFuzzySearch}
          placeHolder="Fuzzy search emails"
          value={fuzzySearch}
        />
      </div>
      <ScrollableTable
        header={(header) => (
          <Th
            key={header.id}
            label={flexRender(
              header.column.columnDef.header,
              header.getContext(),
            )}
            sortDir={header.column.getIsSorted()}
            onClick={header.column.getToggleSortingHandler()}
          >
            {header.column.getCanFilter() && (
              <Input
                className="py-1 px-2 text-sm h-6 border-neutral-200 bg-white font-normal"
                onClick={(event) => event.stopPropagation()}
                value={(header.column.getFilterValue() as string) ?? ""}
                onChange={(event) =>
                  header.column.setFilterValue(event.currentTarget.value)
                }
                placeholder={`Filter by ${header.column.columnDef.header}`}
              />
            )}
          </Th>
        )}
        row={(row) =>
          row.getVisibleCells().map((cell) => (
            <Td key={cell.id} className="px-3 py-3 group-odd:bg-neutral-50">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          ))
        }
        rowSizeEstimate={45}
        table={table}
      />
      <Outlet />
    </div>
  );
});

Customers.displayName = "Customers";
