import type { Header, Row } from "@tanstack/react-table";

const removeCommas = (value: string | undefined) => {
  if (!value) return "";

  return value.replaceAll(",", "");
};

type Columns<K extends string> = (
  | { rowKey: K; label: string }
  | { columnId: string }
)[];

const buildHeader = (
  tableHeaders: Header<unknown, unknown>[],
  columns: Columns<string>,
) => {
  return columns
    .reduce<string[]>((acc, column) => {
      if ("label" in column) return [...acc, column.label];

      const label = tableHeaders
        .find((header) => header.column.id === column.columnId)
        ?.column.columnDef.header?.toString();

      return [...acc, label ?? ""];
    }, [])
    .join(",");
};

export const useDownloadCsv =
  <U extends object, K extends Extract<keyof U, string>>(
    rows: Row<U>[],
    tableHeaders: Header<U, unknown>[],
    columns: Columns<K>,
  ) =>
  (csvName = "report") => {
    const lines = [
      buildHeader(tableHeaders as Header<unknown, unknown>[], columns),
    ];

    rows.forEach((row) => {
      const line: unknown[] = [];

      columns.forEach((column) => {
        if ("rowKey" in column) {
          line.push(removeCommas(row.original[column.rowKey]?.toString()));
        } else {
          line.push(removeCommas(row.getValue(column.columnId)?.toString()));
        }
      });

      lines.push(line.join(","));
    });

    const csv = `data:text/csv;charset=utf-8,${lines.join("\n")}`;
    const encoded = encodeURI(csv);
    const link = document.createElement("a");
    link.setAttribute("href", encoded);
    link.setAttribute("download", `${csvName}.csv`);
    document.body.appendChild(link);
    link.click();
  };
