import { FC } from "react";
import LogoSvg from "../assets/logo.svg";
import { twMerge } from "tailwind-merge";

export const Logo: FC<{ className?: string }> = ({ className }) => {
  return (
    <img
      src={LogoSvg}
      alt="Ninja Nerd Logo"
      className={twMerge("w-12", className)}
    />
  );
};
