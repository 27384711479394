import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const SidebarLayout: FC<PropsWithChildren<{ className?: string }>> = ({
  className,
  children,
}) => {
  return (
    <div
      className={twMerge(
        "absolute right-0 top-0 bottom-0 bg-white drop-shadow p-4 w-96",
        className,
      )}
    >
      <Link className="absolute right-4 top-2" to=".." relative="path">
        <FontAwesomeIcon
          icon={faTimes}
          size="lg"
          className="text-neutral-400"
        />
      </Link>
      <div className="flex flex-col gap-y-4">{children}</div>
    </div>
  );
};
