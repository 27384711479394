import { useLoaderData } from "@/lib/react-router-dom";
import { throwJsonRouteError } from "@/utils/throwJsonRouteError";
import { apiClient } from "@/lib/api-client";
import { getResponseJson } from "@/utils/getResponseJson";
import { User } from "@admin-ui/providers/AuthProvider";
import { authHeader } from "@admin-ui/utils/authHeader";
import { parseDateTimeStrings } from "@/utils/parseDateTimeStrings";
import { Registrations } from "../routes/Registrations";

export const registrationsLoader = async (authenticatedUser: User) => {
  const response = await apiClient.v1.admin.registrations.$get(
    undefined,
    authHeader(authenticatedUser.jwt),
  );
  const {
    data: registrations,
    error,
    status,
  } = await getResponseJson(response);

  if (error) throwJsonRouteError(`Unable to load registrations`, status);

  return registrations.map((registration) =>
    parseDateTimeStrings(registration, "updatedAt", "createdAt", "completedAt"),
  );
};

export type Registrations = Awaited<ReturnType<typeof registrationsLoader>>;

export const useRegistrationsLoader = () => {
  return useLoaderData<Registrations>();
};
