import { FC, ComponentProps } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const BlueLink: FC<ComponentProps<typeof Link>> = ({
  className,
  ...props
}) => {
  return (
    <Link
      {...props}
      className={twMerge("text-blue-600 font-semibold", className)}
    />
  );
};
