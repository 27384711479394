import { apiClient } from "@/lib/api-client";
import { z } from "zod";
import { getResponseJson } from "@/utils/getResponseJson";
import { throwJsonRouteError } from "@/utils/throwJsonRouteError";
import { redirect } from "react-router-dom";
import { confirmEmailSchema, ApiValidationError } from "api";
import { useActionData } from "@/lib/react-router-dom";
import { getFieldErrors } from "@/utils/getFieldErrors";
import { authHeader } from "@admin-ui/utils/authHeader";
import { User } from "@admin-ui/providers/AuthProvider";

type FieldErrors = Partial<z.infer<typeof confirmEmailSchema>>;
type ErrorResponse = { message?: string; fields?: FieldErrors };

export const changeEmailAction = async (
  authenticatedUser: User,
  userId: string | undefined,
  request: Request,
): Promise<ErrorResponse | Response> => {
  if (!userId) throwJsonRouteError("Unable to update customer", 400);

  const parsed = confirmEmailSchema.safeParse(await request.json());

  if (!parsed.success)
    return {
      fields: getFieldErrors(["email", "confirmEmail"], parsed.error.issues),
    };

  const { email, confirmEmail } = parsed.data;

  const response = await apiClient.v1.admin.customers[":id"].email.$put(
    {
      param: { id: userId },
      json: { email, confirmEmail: confirmEmail },
    },
    authHeader(authenticatedUser.jwt),
  );

  const { error } = await getResponseJson(response);

  if (error instanceof ApiValidationError) {
    return {
      message: error.message.join(" "),
      fields: getFieldErrors(["email", "confirmEmail"], error.issues),
    };
  }

  if (error) return { message: error.message.join(" ") };

  return redirect("..");
};

export const useChangeEmailActionData = () => {
  return useActionData<Awaited<ReturnType<typeof changeEmailAction>>>();
};
