import { ZodIssue } from "zod";

type GetFieldErrorsResult<T extends string> = Partial<
  Record<T, string | undefined>
>;

export const getFieldErrors = <T extends string>(
  paths: readonly T[],
  issues: ZodIssue[],
): GetFieldErrorsResult<T> => {
  const fieldErrors: GetFieldErrorsResult<T> = {};

  paths.forEach((path) => {
    fieldErrors[path] = issues.find(
      (issue) => issue.path.join("") === path,
    )?.message;
  });

  return fieldErrors;
};
