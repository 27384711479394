import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { twMerge } from "tailwind-merge";

export const DownloadCsvButton: FC<{
  className?: string;
  onClick: () => void;
}> = ({ className, onClick }) => {
  return (
    <button
      onClick={onClick}
      title="Download table with the same sort and filter state as shown"
      className={twMerge(
        "text-xs ml-4 text-blue-600 font-normal bg-neutral-100 p-1 px-2 border-neutral-200 border rounded",
        className,
      )}
    >
      <FontAwesomeIcon icon={faSave} className="mr-1 text-neutral-500" />
      Download as CSV
    </button>
  );
};
