import { DateTime } from "luxon";
import { StringKeysOf } from "../types";
import { parseDateTimeString } from "./parseDateTimeString";

type Output<T extends object, K extends StringKeysOf<T>> = Omit<T, K> & {
  [Key in K]: Exclude<T[Key], string> | DateTime<true>;
};

export const parseDateTimeStrings = <
  T extends object,
  K extends StringKeysOf<T>,
>(
  rec: T,
  ...keys: K[]
): Output<T, K> => {
  let result: Partial<Output<T, K>> = {};

  for (const key of keys) {
    const f = rec[key];

    if (!f) continue;
    if (typeof f !== "string") continue;

    const parsed = parseDateTimeString(f);

    if (parsed === null) continue;

    result = { ...result, [key]: parsed };
  }

  return { ...rec, ...result } as Output<T, K>;
};
