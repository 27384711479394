import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, PropsWithChildren } from "react";
import { Link, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const Modal: FC<PropsWithChildren<{ className?: string }>> = ({
  className,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="fixed left-0 top-0 right-0 bottom-0 bg-neutral-800 bg-opacity-30 flex flex-col items-center justify-center"
      onClick={() => navigate("..", { replace: true })}
    >
      <div
        className={twMerge("rounded bg-white relative shadow", className)}
        onClick={(event) => event.stopPropagation()}
      >
        <Link
          to=".."
          className="absolute right-2 top-2 text-neutral-400"
          replace={true}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Link>
        {children}
      </div>
    </div>
  );
};
