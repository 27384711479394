import { FC, ComponentType } from "react";
import merge from "deepmerge";

export const applyPropsTo =
  <T extends object>(
    Component: ComponentType<T>,
    defaultProps: Partial<T>,
  ): FC<T> =>
  (props) => {
    const mergedProps = merge<T>(defaultProps, props);
    return <Component {...mergedProps} />;
  };
