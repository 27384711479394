import { Input, Label } from "../form";
import {
  Controller,
  ControllerProps,
  FieldPath,
  useFormContext,
} from "react-hook-form";
import { ReactNode, useId } from "react";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

interface Props<T extends z.ZodSchema<object>> {
  autoComplete?: string;
  children?: ReactNode | ControllerProps<z.infer<T>>["render"];
  displayName: string;
  error?: string;
  id?: string;
  name: FieldPath<z.infer<T>>;
  tabIndex?: number;
  testId?: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
}

export const Fieldset = <T extends z.ZodSchema<object>>({
  autoComplete,
  children,
  error: errorOverride,
  displayName,
  id: idOverride,
  name,
  tabIndex,
  testId,
  type = "text",
  placeholder,
  required = false,
}: Props<T>) => {
  const generatedId = useId();
  const id = idOverride ?? generatedId;

  const {
    control,
    register,
    formState: { isSubmitting },
    getFieldState,
  } = useFormContext<z.infer<T>>();
  const { error: fieldValidationError } = getFieldState(name);

  const error = errorOverride
    ? { message: errorOverride }
    : fieldValidationError;

  const divClasses = twMerge(
    "mb-4",
    error && "border-l-4 border-brand-red-glasses pl-4",
  );

  return (
    <div className={`${divClasses}`}>
      <Label htmlFor={id} showAsterisk={required} accentuate={Boolean(error)}>
        {displayName}
      </Label>
      {!children && (
        <Input
          autoComplete={autoComplete}
          data-testid={testId}
          id={id}
          tabIndex={tabIndex}
          type={type}
          {...register(name)}
          disabled={isSubmitting}
          placeholder={placeholder}
        />
      )}{" "}
      {typeof children === "function" ? (
        <Controller control={control} name={name} render={children} />
      ) : (
        children
      )}
      {error && typeof error === "object" && "message" in error && (
        <span className="block text-brand-red-glasses mt-2">
          {error.message}
        </span>
      )}
    </div>
  );
};
