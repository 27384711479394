import { ErrorBanner } from ".";
import {
  FormProvider,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { ReactNode } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { twMerge } from "tailwind-merge";

interface Props<T extends z.ZodSchema<object>> {
  className?: string;
  children: ReactNode | ((formMethods: UseFormReturn<z.infer<T>>) => ReactNode);
  onSuccess: (data: z.infer<T>) => void;
  schema: T;
  defaultValues?: UseFormProps<z.infer<T>>["defaultValues"];
}

export function Form<T extends z.ZodSchema<object>>({
  className,
  children,
  onSuccess,
  schema,
  defaultValues,
}: Props<T>) {
  const formMethods = useForm<z.infer<T>>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const errorsPresent = Object.keys(formMethods.formState.errors).length > 0;

  return (
    <FormProvider {...formMethods}>
      <form
        className={twMerge("space-y-4", className)}
        onSubmit={formMethods.handleSubmit(onSuccess)}
      >
        {errorsPresent && <ErrorBanner />}
        {typeof children === "function" ? children(formMethods) : children}
      </form>
    </FormProvider>
  );
}
