import { InlineBanner } from "../form";
import { useFormContext } from "react-hook-form";

export const ErrorBanner = () => {
  const formCtx = useFormContext();
  const errors = formCtx.formState.errors;
  // const rootErrors = errors.root;
  // const fieldErrors = errors.fieldErrors;

  const multipleFieldErrorsMsg =
    "There are a few problems with the fields below: ";
  const singleFieldErrorMsg = (name: string) =>
    `There is a problem with the ${name} field below:`;
  const fieldErrorMsg = `${
    errors && Object.keys(errors).length === 1
      ? singleFieldErrorMsg(Object.keys(errors)[0]!)
      : multipleFieldErrorsMsg
  }`;

  return (
    <InlineBanner type="error">
      {/* {rootErrors && <p className="font-bold text-white">{base}</p>} */}

      <p>{fieldErrorMsg}</p>
    </InlineBanner>
  );
};
