import { Input, Label } from "@/components";
import { FC } from "react";

interface Props {
  enableHint: boolean;
  label: string;
  onChange: (value: string) => void;
  placeHolder?: string;
  value: string | undefined;
}

export const FuzzySearch: FC<Props> = ({
  enableHint,
  label,
  onChange,
  placeHolder,
  value,
}) => {
  return (
    <>
      <Label htmlFor="fuzzy">{label}</Label>
      <Input
        id="fuzzy"
        className="w-64 bg-white mb-2"
        value={value ?? ""}
        onChange={(event) => onChange(event.currentTarget.value)}
        placeholder={placeHolder}
      />
      {enableHint && value && value.trim().length > 1 ? (
        <span className="text-green-700 h-6 text-sm">
          The results below are sorted by fuzzy search relevance
        </span>
      ) : (
        <div className="empty:h-6"></div>
      )}
    </>
  );
};
