import { Button } from "../Button";
import { DetailedHTMLProps, ButtonHTMLAttributes, FC } from "react";
import { useFormContext } from "react-hook-form";

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  autoTextSize?: boolean;
  className?: string;
  variant?: "default" | "outlined" | "subtle";
  icon?: boolean;
  loading?: boolean;
  maxAutoFontSizePx?: number;
}

export const SubmitButton: FC<Props> = (props) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  const isPending = props.loading || isSubmitting;

  return (
    <Button {...props} type="submit" showSpinner={isPending}>
      {props.children}
    </Button>
  );
};
