import { MainLayout } from "../components/MainLayout";
import { routes as customersRoutes } from "../features/customers";
import { routes as registrationsRoutes } from "../features/registrations";
import { FC, useMemo } from "react";
import { Navigate, RouterProvider, createHashRouter } from "react-router-dom";
import { RootErrorComponent } from "../components/RootErrorComponent";
import { useAuth, User } from "@admin-ui/providers/AuthProvider";

const router = (user: User) =>
  createHashRouter([
    { index: true, element: <Navigate to="/customers" replace /> },
    {
      path: "/",
      element: <MainLayout />,
      children: [...customersRoutes(user), ...registrationsRoutes(user)],
      errorElement: <RootErrorComponent />,
    },
  ]);

export const AppRoutes: FC = () => {
  const authenticatedUser = useAuth();

  const routes = useMemo(() => router(authenticatedUser), [authenticatedUser]);

  return <RouterProvider router={routes} />;
};
