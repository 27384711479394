import { AuthProvider } from "./AuthProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FC, ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { ModalBackground, ModalWindow } from "@/components";

const navigateToAppRoot = () => () => {
  const appRoot = `${window.location.origin}${window.location.pathname}`;
  window.location.replace(appRoot);
};

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <Sentry.ErrorBoundary
    fallback={({ error, componentStack }) => (
      <ModalBackground useNavigateOverride={navigateToAppRoot}>
        <ModalWindow title="Whoops!" useNavigateOverride={navigateToAppRoot}>
          <div className="flex flex-col space-y-2">
            <p>
              You have encountered an error. We are aware of it, and will push
              out a fix as soon as possible.
            </p>
            <hr className="text-neutral-200" />
            <p className="text-neutral-600 text-sm">{error.toString()}</p>
            <div className="overflow-x-scroll max-w-full text-xs bg-yellow-100 text-neutral-700 p-2">
              <pre>{componentStack.trim()}</pre>
            </div>
          </div>
        </ModalWindow>
      </ModalBackground>
    )}
  >
    <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
      <AuthProvider>{children}</AuthProvider>
    </GoogleOAuthProvider>
  </Sentry.ErrorBoundary>
);
