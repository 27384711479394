import z from "zod";

export const customerProfileSchema = z.object({
  name: z.string().min(1),
  country: z.string().min(1),
  institution: z.string().min(1),
  student: z.boolean(),
  field: z.string().min(1),
  year: z.coerce
    .number()
    .min(2000)
    .max(2100)
    .transform((val) => String(val)),
});

export const changeAccountEmailSchema = z
  .object({
    email: z.string({ required_error: "Email is required" }).email(),
    confirmEmail: z
      .string({ required_error: "Confirmation email is required" })
      .email(),
    password: z.string(),
  })
  .superRefine((values, ctx: z.RefinementCtx) => {
    if (values.email === values.confirmEmail) return true;

    ctx.addIssue({
      path: ["confirmEmail"],
      message: "Confirmation email does not match email",
      code: z.ZodIssueCode.custom,
    });
  });

export const changePasswordSchema = z
  .object({
    oldPassword: z.string(),
    password: z.string(),
    confirmPassword: z.string(),
  })
  .superRefine((values, ctx) => {
    if (values.password === values.confirmPassword) return true;

    ctx.addIssue({
      path: ["confirmPassword"],
      message: "Confirmation password does not match password",
      code: z.ZodIssueCode.custom,
    });
  });
