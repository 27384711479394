import { RouteObject } from "react-router-dom";
import { ErrorComponent } from "../../../components/ErrorComponent";
import { customersLoader } from "../loaders/customersLoader";
import { Customers } from "./Customers";
import { Customer } from "./Customer";
import { customerLoader } from "../loaders/customerLoader";
import { CustomerErrorComponent } from "../components/CustomerErrorComponent";
import { ChangeEmail } from "./ChangeEmail";
import { changeEmailAction } from "../actions/changeEmailAction";
import type { User } from "@admin-ui/providers/AuthProvider";

export const routes = (authenticatedUser: User): RouteObject[] => [
  {
    path: "customers",
    element: <Customers />,
    loader: () => customersLoader(authenticatedUser),
    errorElement: <ErrorComponent />,
    children: [
      {
        path: ":id",
        element: <Customer />,
        loader: ({ params }) => customerLoader(authenticatedUser, params.id),
        errorElement: <CustomerErrorComponent />,
        children: [
          {
            path: "change-email",
            action: async ({ params, request }) => {
              const id = params.id;
              return changeEmailAction(authenticatedUser, id, request);
            },
            element: <ChangeEmail />,
            loader: ({ params }) =>
              customerLoader(authenticatedUser, params.id),
            errorElement: <ErrorComponent />,
          },
        ],
      },
    ],
  },
];
