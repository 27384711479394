import { FC } from "react";
import { apiClient } from "@/lib/api-client";
import { useCustomerLoader } from "../loaders/customerLoader";
import { SidebarLayout } from "../components/SidebarLayout";
import { Outlet } from "react-router-dom";
import { BlueLink } from "@admin-ui/components/BlueLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@/components";
import { applyPropsTo } from "../../../utils/applyPropsTo";
import { Link } from "@/lib/react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "@admin-ui/providers/AuthProvider";
import { authHeader } from "@admin-ui/utils/authHeader";
import { twMerge } from "tailwind-merge";

const Detail: FC<{ label: string; value: string | null }> = ({
  label,
  value,
}) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <span>{label}</span>
      <code className="text-sm bg-neutral-100 px-1 rounded-sm">
        {value ?? "N/A"}
      </code>
    </div>
  );
};

const ActionButton = applyPropsTo(Button, {
  variant: "outlined",
  className: "h-6 text-sm",
});

const ActionButtonLink: FC<{
  label: string;
  link?: string | null;
  missingLinkTitle: string;
}> = ({ label, link, missingLinkTitle }) => {
  const baseStyles = "border-2 rounded-md text-sm text-center font-semibold";

  if (!link)
    return (
      <span
        className={twMerge(baseStyles, "text-neutral-400 cursor-not-allowed")}
        title={missingLinkTitle}
      >
        {label}
      </span>
    );

  return (
    <a
      href={link}
      className={twMerge(baseStyles, "border-neutral-500 text-neutral-600")}
      target="_blank"
    >
      {label}
    </a>
  );
};

const showErrorToast = () => {
  toast("Email failed to send.  Try again in a moment", {
    type: "error",
  });
};

export const Customer: FC = () => {
  const customer = useCustomerLoader();
  const authenticatedUser = useAuth();

  const resendLatestReceipt = async () => {
    const id = toast("Requesting Receipt from Stripe");
    try {
      const response = await apiClient.v1.admin.customers[":id"][
        "send-latest-receipt"
      ].$post(
        {
          param: { id: customer.userId },
        },
        authHeader(authenticatedUser.jwt),
      );

      switch (response.status) {
        case 404:
          toast("Unable to find customer. Email was not sent", {
            type: "error",
          });
          break;
        case 204:
          toast("No recent payment information exists.  Nothing to send", {
            type: "info",
          });
          break;
        case 200:
          toast("Receipt sent successfully!", { type: "success" });
          break;
        default:
          showErrorToast();
      }
    } catch (e) {
      console.error(e);
      showErrorToast();
    } finally {
      toast.dismiss(id);
    }
  };

  const emailPaymentUpdateLink = async () => {
    try {
      const response = await apiClient.v1.admin.customers[":id"][
        "send-update-payment-link"
      ].$post(
        {
          param: { id: customer.userId },
        },
        authHeader(authenticatedUser.jwt),
      );

      switch (response.status) {
        case 404:
          toast("Unable to find customer. Email was not sent", {
            type: "error",
          });
          break;
        case 200:
          toast("Update payment link sent!", { type: "success" });
          break;
        default:
          showErrorToast();
      }
    } catch (e) {
      console.error(e);
      showErrorToast();
    }
  };

  const emailPasswordResetLink = async () => {
    try {
      const response = await apiClient.v1.admin.customers[":id"][
        "send-password-reset-instructions"
      ].$post(
        {
          param: { id: customer.userId },
        },
        authHeader(authenticatedUser.jwt),
      );

      switch (response.status) {
        case 404:
          toast("Unable to find customer. Email was not sent", {
            type: "error",
          });
          break;
        case 200:
          toast("Reset password link sent!", { type: "success" });
          break;
        default:
          showErrorToast();
      }
    } catch (e) {
      console.error(e);
      showErrorToast();
    }
  };

  return (
    <>
      <SidebarLayout>
        <h1 className="text-2xl">Customer Details</h1>
        <section className="rounded bg-neutral-100 p-2">
          <h1 className="font-bold text-lg">{customer.name}</h1>
          <div className="flex flex-row gap-x-2 text-sm">
            <span>{customer.institution}</span>
            <span>-</span>
            <span>{customer.field}</span>
            <span>-</span>
            <span>{customer.isStudent ? "student" : "non-student"}</span>
          </div>
        </section>
        <div>
          <h2 className="font-bold text-md">Emails</h2>
          <Detail label="User Email" value={customer.userEmail} />
          <Detail label="Profile Email" value={customer.profileEmail} />
          <BlueLink className="text-sm" to="change-email">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            Change Email
          </BlueLink>
        </div>
        <div className="flex flex-col space-y-2">
          <h2 className="font-bold text-md">Actions</h2>
          <Link to="change-email">
            <ActionButton>Change email</ActionButton>
          </Link>
          <ActionButton onClick={emailPasswordResetLink}>
            Email password reset link
          </ActionButton>
          <ActionButton onClick={emailPaymentUpdateLink}>
            Email payment update link
          </ActionButton>
          <ActionButtonLink
            label="Manage subscription at Stripe"
            link={
              customer.stripeCustomerId &&
              `https://dashboard.stripe.com/customers/${customer.stripeCustomerId}`
            }
            missingLinkTitle={`${customer.name} is not a Stripe customer`}
          />
          <ActionButtonLink
            label="Manage subscription at PayPal"
            link={
              customer.paypalCustomerId &&
              `https://www.paypal.com/billing/subscriptions/${customer.paypalCustomerId}`
            }
            missingLinkTitle={`${customer.name} is not a PayPal customer`}
          />
          <ActionButton
            onClick={resendLatestReceipt}
            disabled={!customer.stripeCustomerId}
          >
            Resend latest Stripe receipt
          </ActionButton>
        </div>
        <div>
          <h2 className="font-bold text-md">Subscription Info</h2>
          <Detail label="Latest Plan Name" value={customer.latestPlanName} />
          <Detail
            label="Latest Subscription Status"
            value={customer.latestSubscriptionStatus}
          />
          <Detail
            label="Memberstack Member ID"
            value={customer.memberStackMemberId}
          />
          <Detail
            label="Stripe Customer ID"
            value={customer.stripeCustomerId}
          />
          <Detail
            label="Paypal Customer Id"
            value={customer.paypalCustomerId}
          />
        </div>
      </SidebarLayout>
      <Outlet />
    </>
  );
};
