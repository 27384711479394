import { ErrorComponent } from "../../../components/ErrorComponent";
import { SidebarLayout } from "./SidebarLayout";

export const CustomerErrorComponent = () => {
  return (
    <SidebarLayout>
      <ErrorComponent />
    </SidebarLayout>
  );
};
