import { FC } from "react";
import { SidebarLayout } from "../components/SidebarLayout";
import { Form, useNavigation } from "react-router-dom";
import { Button } from "@/components";
import { applyPropsTo } from "../../../utils/applyPropsTo";
import { DateTime } from "luxon";
import { useRegistrationLoader } from "../loaders/registrationLoader";

const Detail: FC<{
  label: string;
  value: string | null | undefined | number;
}> = ({ label, value }) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <span>{label}:</span>
      <code className="text-sm bg-neutral-100 px-1 rounded-sm">
        {value ?? "N/A"}
      </code>
    </div>
  );
};

const ActionButton = applyPropsTo(Button, {
  variant: "outlined",
  className: "h-6 text-sm",
  type: "submit",
});

export const Registration: FC = () => {
  const registration = useRegistrationLoader();
  const navigation = useNavigation();

  return (
    <>
      <SidebarLayout className="w-[550px]">
        <h1 className="text-2xl">Registration Details</h1>
        <div>
          <h2 className="font-bold text-md">Registration</h2>
          <Detail label="Name" value={registration.registrationName} />
          <Detail label="Email" value={registration.registrationEmail} />
          {registration.paypalSubscriptionId && (
            <Detail
              label="Paypal Subscription ID"
              value={registration.paypalSubscriptionId}
            />
          )}
          {registration.paypalSubscriptionId && (
            <Detail
              label="Stripe Checkout ID"
              value={registration.stripeCheckoutId}
            />
          )}
          <Detail
            label="Created At"
            value={registration.createdAt.toLocaleString(
              DateTime.DATETIME_FULL_WITH_SECONDS,
            )}
          />
          <Detail
            label="Updated At"
            value={registration.updatedAt.toLocaleString(
              DateTime.DATETIME_FULL_WITH_SECONDS,
            )}
          />
          <Detail
            label="Completed At"
            value={registration.completedAt?.toLocaleString(
              DateTime.DATETIME_FULL_WITH_SECONDS,
            )}
          />
        </div>
        <div>
          <h2 className="font-bold text-md">User</h2>
          <Detail label="Email" value={registration.userEmail} />
          <Detail label="Sign In Count" value={registration.signInCount ?? 0} />
        </div>
        <div>
          <h2 className="font-bold text-md">Plan</h2>
          <Detail label="Name" value={registration.planName} />
          {registration.planStripePriceId && (
            <Detail
              label="Stripe Price ID"
              value={registration.planStripePriceId}
            />
          )}
          {registration.planPaypalId && (
            <Detail label="Paypal Plan Id" value={registration.planPaypalId} />
          )}
        </div>
        <div className="flex flex-col space-y-2">
          <h2 className="font-bold text-md">Actions</h2>
          <Form method="post">
            <ActionButton
              disabled={
                Boolean(registration.userId) ||
                navigation.state === "submitting"
              }
            >
              {navigation.state === "submitting" ? "Signing up..." : "Sign up"}
            </ActionButton>
          </Form>
        </div>
      </SidebarLayout>
    </>
  );
};
