import { useLoaderData } from "@/lib/react-router-dom";
import { throwJsonRouteError } from "@/utils/throwJsonRouteError";
import { apiClient } from "@/lib/api-client";
import { getResponseJson } from "@/utils/getResponseJson";
import { User } from "@admin-ui/providers/AuthProvider";
import { authHeader } from "@admin-ui/utils/authHeader";
import { parseDateTimeStrings } from "@/utils/parseDateTimeStrings";

export const customersLoader = async (authenticatedUser: User) => {
  const response = await apiClient.v1.admin.customers.$get(
    undefined,
    authHeader(authenticatedUser.jwt),
  );
  const { data: customers, error, status } = await getResponseJson(response);

  if (error) throwJsonRouteError(`Unable to load customers`, status);

  return customers.map((customer) => parseDateTimeStrings(customer, "joined"));
};

export const useCustomersLoader = () => {
  return useLoaderData<Awaited<ReturnType<typeof customersLoader>>>();
};
