import { DetailedHTMLProps, FC, TdHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

type Props = DetailedHTMLProps<
  TdHTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
> & { showLoading?: boolean };

export const Td: FC<Props> = ({
  children,
  className,
  showLoading = false,
  ...props
}) => {
  return (
    <td
      className={twMerge(
        "border-b border-neutral-100 text-neutral-800 relative whitespace-nowrap",
        props.onClick && "cursor-pointer",
        className,
      )}
      {...props}
    >
      {showLoading && (
        <div className="absolute left-3 right-3 top-0 bottom-0 flex flex-col justify-center items-center">
          <div className="h-5 w-full animate-pulse bg-neutral-200"></div>
        </div>
      )}
      <span className={twMerge(showLoading && "opacity-0")}>{children}</span>
    </td>
  );
};
