import { CheckCircle, DiamondExclamation } from "../icons";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children: ReactNode;
  type: "success" | "alert" | "error";
}

export const InlineBanner = (props: Props) => {
  const classes = twMerge(
    "mb-4 border-2 rounded-sm p-3 flex",
    props.type === "success" && "border-successs-900 bg-success-100",
    props.type === "alert" && "border-warning-800 bg-warning-100",
    props.type === "error" && "border-error-700 bg-error-100",
  );

  const iconClasses = twMerge(
    props.type === "success" && "text-success-900",
    props.type === "alert" && "text-warning-800",
    props.type === "error" && "text-error-700",
  );

  return (
    <div className={classes}>
      <div className={iconClasses}>
        {props.type === "success" && <CheckCircle />}
        {props.type === "alert" && <DiamondExclamation />}
        {props.type === "error" && <DiamondExclamation />}
      </div>
      <div className="pl-3">{props.children}</div>
    </div>
  );
};
