import { hc } from "hono/client";
import { coerceIntoArray } from "../utils/coerceIntoArray";
import { ZodIssue } from "zod";
import type { AppType } from "../types";

export class ApiError {
  constructor(public message = ["Internal server error"]) {}
}

export type FieldError = { path: string[]; message: string };

export class ApiValidationError extends ApiError {
  constructor(
    message: string | string[],
    public issues: ZodIssue[],
  ) {
    super(coerceIntoArray(message));
  }
}

export const buildApiClient = hc<AppType>;
