import { noop } from "@tanstack/react-table";
import { useEffect, useState } from "react";

interface Options<T> {
  timeout?: number;
  onChangeDebounced?: (val: T) => void;
}

export const useDebouncedState = <T>(
  initialState: T,
  { timeout = 300, onChangeDebounced = noop }: Options<T> = {
    timeout: 300,
    onChangeDebounced: noop,
  },
) => {
  const [state, setState] = useState<T>(initialState);
  const [debounced, setDebounced] = useState<T>(state);

  useEffect(() => {
    const id = setTimeout(() => {
      setDebounced(state);
      onChangeDebounced(state);
    }, timeout);

    return () => clearTimeout(id);
  }, [state, timeout, onChangeDebounced]);

  return { state, debounced, setState };
};
