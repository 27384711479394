import { FC, memo, useMemo, useState } from "react";
import { useRegistrationsLoader as useRegistrationsLoader } from "../loaders/registrationsLoader";
import { Input } from "@/components";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
} from "@tanstack/react-table";
import { ScrollableTable, Td, Th } from "@admin-ui/components/tables";
import { Outlet } from "react-router-dom";
import Fuse from "fuse.js";
import { useDebouncedState } from "@/hooks/useDebouncedState";
import { BlueLink } from "@admin-ui/components/BlueLink";
import { DateTime } from "luxon";
import { FuzzySearch } from "@admin-ui/components/FuzzySearch";
import { DownloadCsvButton } from "@admin-ui/components/DownloadCsvButton";
import { useDownloadCsv } from "@admin-ui/hooks/useDownloadCsv";

const columnHelper =
  createColumnHelper<ReturnType<typeof useRegistrationsLoader>[number]>();

const columns = [
  columnHelper.accessor("registrationEmail", {
    filterFn: "includesString",
    header: "Email",
  }),
  columnHelper.accessor("registrationName", {
    filterFn: "includesString",
    header: "Name",
    cell: ({ row, getValue }) => {
      return <BlueLink to={row.original.registrationId}>{getValue()}</BlueLink>;
    },
  }),
  columnHelper.accessor("createdAt", {
    enableColumnFilter: false,
    header: "Created",
    cell: ({ getValue }) => {
      return getValue().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
    },
  }),
  columnHelper.accessor("completedAt", {
    enableColumnFilter: false,
    header: "Completed",
    cell: ({ getValue }) => {
      return getValue()?.toLocaleString(DateTime.DATETIME_FULL);
    },
  }),
  columnHelper.accessor(
    (row) => {
      if (row.stripeCheckoutId) return "Stripe" as const;
      if (row.paypalSubscriptionId) return "PayPal" as const;

      return "N/A" as const;
    },
    {
      header: "Payment Processor",
      filterFn: "includesString",
      id: "paymentProcessor",
    },
  ),
  columnHelper.accessor("userId", {
    filterFn: "includesString",
    header: "Customer Email",
    cell: ({ getValue, row }) => {
      const id = getValue();
      if (!id) return;

      return (
        <BlueLink to={`../customers/${id}`}>{row.original.userEmail}</BlueLink>
      );
    },
  }),
];

export const Registrations: FC = memo(() => {
  const registrations = useRegistrationsLoader();
  const [sorting, setSorting] = useState<SortingState>([]);

  const {
    state: fuzzySearch,
    debounced: debouncedFuzzySearch,
    setState: setFuzzySearch,
  } = useDebouncedState("");

  const fuse = useMemo(
    () =>
      new Fuse(registrations, {
        keys: ["registrationEmail", "userEmail"],
        findAllMatches: true,
      }),
    [registrations],
  );

  const fuzzySearchedItems = useMemo(() => {
    return fuse.search(debouncedFuzzySearch).map((result) => result.item);
  }, [fuse, debouncedFuzzySearch]);

  const data =
    debouncedFuzzySearch.trim() === "" ? registrations : fuzzySearchedItems;

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const downloadCsv = useDownloadCsv(
    table.getRowModel().rows,
    table.getFlatHeaders(),
    [
      { rowKey: "registrationId", label: "ID" },
      { columnId: "registrationEmail" },
      { columnId: "registrationName" },
      { columnId: "createdAt" },
      { columnId: "completedAt" },
      { columnId: "paymentProcessor" },
      { rowKey: "userId", label: "User ID" },
    ],
  );

  return (
    <div className="flex flex-col h-full relative">
      <div className="p-4">
        <h1 className="font-semibold text-lg flex flex-row items-center">
          Registrations
          <DownloadCsvButton
            onClick={() =>
              downloadCsv(`registrations-${DateTime.now().toISO()}`)
            }
          />
        </h1>
        <FuzzySearch
          enableHint={sorting.length === 0}
          label="Search registration email and customer email with fuzzy search"
          onChange={setFuzzySearch}
          placeHolder="Fuzzy search emails"
          value={fuzzySearch}
        />
      </div>
      <ScrollableTable
        header={(header) => (
          <Th
            key={header.id}
            label={flexRender(
              header.column.columnDef.header,
              header.getContext(),
            )}
            sortDir={header.column.getIsSorted()}
            onClick={header.column.getToggleSortingHandler()}
          >
            {header.column.getCanFilter() && (
              <Input
                className="py-1 px-2 text-sm h-6 border-neutral-200 bg-white font-normal"
                onClick={(event) => event.stopPropagation()}
                value={header.column.getFilterValue() as string}
                onChange={(event) =>
                  header.column.setFilterValue(event.currentTarget.value)
                }
                placeholder={`Filter by ${header.column.columnDef.header}`}
              />
            )}
          </Th>
        )}
        row={(row) =>
          row.getVisibleCells().map((cell) => (
            <Td key={cell.id} className="px-3 py-3 group-odd:bg-neutral-50">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          ))
        }
        rowSizeEstimate={45}
        table={table}
      />
      <Outlet />
    </div>
  );
});

Registrations.displayName = "Registrations";
