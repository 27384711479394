import { useLoaderData } from "@/lib/react-router-dom";
import { throwJsonRouteError } from "@/utils/throwJsonRouteError";
import { apiClient } from "@/lib/api-client";
import { authHeader } from "@admin-ui/utils/authHeader";
import { User } from "@admin-ui/providers/AuthProvider";
import { getResponseJson } from "@/utils/getResponseJson";
import { parseDateTimeStrings } from "@/utils/parseDateTimeStrings";

export const registrationLoader = async (
  authenticatedUser: User,
  registrationId: string | undefined,
) => {
  if (!registrationId) throwJsonRouteError(`registration id not set`, 400);

  const response = await apiClient.v1.admin.registrations[":id"].$get(
    { param: { id: registrationId } },
    authHeader(authenticatedUser.jwt),
  );
  const { data: registration, error, status } = await getResponseJson(response);

  if (error) throwJsonRouteError(`Unable to load registrations`, status);

  return parseDateTimeStrings(
    registration,
    "createdAt",
    "updatedAt",
    "completedAt",
  );
};

export const useRegistrationLoader = () => {
  return useLoaderData<Awaited<ReturnType<typeof registrationLoader>>>();
};
