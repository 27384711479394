import { DetailedHTMLProps, FC, LabelHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface Props
  extends DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  accentuate?: boolean;
  showAsterisk?: boolean;
}

export const Label: FC<Props> = ({
  className,
  accentuate = false,
  showAsterisk = false,
  ...props
}) => {
  return (
    <label
      {...props}
      className={twMerge(
        "block mb-2 text-md font-medium text-neutral-900",
        showAsterisk &&
          "after:content-['*'] after:font-bold after:text-brand-red-glasses after:ml-0.5",
        accentuate && "text-brand-red-glasses font-semibold",
        className,
      )}
    />
  );
};
