import { apiClient } from "@/lib/api-client";
import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import logo from "./logo.svg";
import { ModalBackground, ModalWindow } from "index";

export interface User {
  id: string;
  email: string;
  jwt: string;
}

const AuthContext = createContext<User | null>(null);

type UseGoogleOneTapLoginOptions = Parameters<typeof useGoogleOneTapLogin>[0];

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isPending, setIsPending] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showRefreshModal, setShowRefreshModal] = useState(false);

  const authenticateWithApi = async (
    jwt: string | undefined,
    options = { refreshing: false },
  ) => {
    if (!jwt) return setError("Unable to authenticate");

    if (!options.refreshing) setIsPending(true);
    setError(null);
    try {
      const response = await apiClient.v1.admin.auth.$post({ json: { jwt } });
      const body = await response.json();
      if ("message" in body) {
        setUser(null);
        setError(body.message);
      } else {
        setUser({ id: body.id, email: body.email, jwt });
        setTimeout(() => setShowRefreshModal(true), 45 * 60 * 1000); // 45 minutes
        setShowRefreshModal(false);
        setError(null);
      }
      setIsPending(false);
    } catch (e) {
      setUser(null);
      setError(
        e instanceof Error
          ? e.message
          : "Unknown error occurred when authenticating with api",
      );
      setIsPending(false);
    }
  };

  useGoogleOneTapLogin({
    onSuccess: (response) => authenticateWithApi(response.credential),
    onError: () => setError("Unable to start google login flow"),
    use_fedcm_for_prompt: true,
  } as UseGoogleOneTapLoginOptions); // @react-oauth/google's types are updated properly

  return (
    <AuthContext.Provider value={user}>
      {user === null && (
        <div className="flex flex-grow flex-col h-full w-full items-center justify-center">
          <img src={logo} className="h-14" alt="Ninja Nerd" />
          <h1 className="mb-4 font-bold">Admin</h1>
          <p className="italic text-xs">Sign in before continuing</p>
          <GoogleLogin
            onSuccess={(response) => authenticateWithApi(response.credential)}
            onError={() => setError("Unable to start google login flow")}
            use_fedcm_for_prompt={true}
          />
        </div>
      )}
      {error ? (
        <p className="text-red-500">{error}</p>
      ) : isPending ? (
        <p className="animate-pulse">Logging in...</p>
      ) : user === null ? null : (
        children
      )}
      {showRefreshModal && (
        <ModalBackground useNavigateOverride={() => () => undefined}>
          <ModalWindow
            title="Refresh Session"
            useNavigateOverride={() => () => undefined}
            closeable={false}
          >
            <div className="flex flex-col items-center gap-y-2">
              <p>
                Your session is about to expire, please sign back in to continue
              </p>
              <GoogleLogin
                onSuccess={(response) =>
                  authenticateWithApi(response.credential, { refreshing: true })
                }
                onError={() => setError("Unable to refresh session")}
                use_fedcm_for_prompt={true}
              />
            </div>
          </ModalWindow>
        </ModalBackground>
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const auth = useContext(AuthContext);

  if (auth === null)
    throw new Error(
      "Unreachable.  useAuth should only be used within the children of AuthProvider",
    );

  return auth;
};
