import { FC } from "react";
import { Logo } from "./Logo";
import { NavLink, Outlet } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const navLinkClasses = ({ isActive }: { isActive: boolean }) =>
  twMerge("text-blue-600", isActive && "font-bold");

export const MainLayout: FC = () => {
  return (
    <div className="flex-grow flex flex-col">
      <div className="full flex flex-row items-center space-x-4 border-b border-neutral-200 drop-shadow-sm p-4">
        <Logo className="h-10 mr-4" />
        <NavLink to="customers" className={navLinkClasses}>
          Customers
        </NavLink>
        <NavLink to="registrations" className={navLinkClasses}>
          Registrations
        </NavLink>
      </div>
      <div className="flex-1 flex flex-col">
        <Outlet />
      </div>
    </div>
  );
};
