import { isSuccess } from "./isSuccess";
import { ApiError, ApiValidationError } from "api";

type SuccessResult<T> = {
  data: T;
  error: undefined;
  status: number;
};

type FailureResult = {
  data: undefined;
  error: ApiError;
  status: number;
};

type ValidationFailureResult = {
  data: undefined;
  error: ApiValidationError;
  status: 400;
};

type Result<T> = SuccessResult<T> | FailureResult | ValidationFailureResult;

// TODO - "instanceof" checks aren't working.  Need to duck-type with properties instead
export const getResponseJson = async <T extends object>(response: {
  json: () => Promise<T>;
  status: number;
}): Promise<Result<T>> => {
  const data: T | ApiError | ApiValidationError | null = await response.json();

  if (data instanceof ApiValidationError) {
    return { data: undefined, error: data, status: 400 };
  }

  if (data instanceof ApiError) {
    return { data: undefined, error: data, status: response.status };
  }

  if (isSuccess(response) && data !== null) {
    return { data, error: undefined, status: response.status };
  }

  // TODO - remove once the "instance of" checks are removed and replaced with something that checks properly
  if (!isSuccess(response) && data !== null) {
    return {
      data: undefined,
      error: new ApiError([
        "message" in data ? (data.message as string) : JSON.stringify(data),
      ]),
      status: response.status,
    };
  }

  throw new Error(
    "Unreachable, an error type other than ApiError or ApiValidationError cannot be used.  Data might have been null",
  );
};
