import { useRouteError } from "@/lib/react-router-dom";

export const RootErrorComponent = () => {
  const error = useRouteError<Error>();

  return (
    <div className="flex flex-col p-4">
      <span className="font-bold text-red-600 mb-2">{error.message}</span>
      {error.stack && (
        <pre className="text-sm rounded p-2 bg-neutral-100 font-mono text-neutral-800">
          {error.stack}
        </pre>
      )}
    </div>
  );
};
