import { useLoaderData } from "@/lib/react-router-dom";
import { throwJsonRouteError } from "@/utils/throwJsonRouteError";
import { apiClient } from "@/lib/api-client";
import { authHeader } from "@admin-ui/utils/authHeader";
import { User } from "@admin-ui/providers/AuthProvider";
import { parseDateTimeStrings } from "@/utils/parseDateTimeStrings";

export const customerLoader = async (
  authenticatedUser: User,
  userId?: string,
) => {
  if (!userId) throwJsonRouteError(`Unable to load customer`, 404);

  const response = await apiClient.v1.admin.customers[":id"].$get(
    {
      param: { id: userId },
    },
    authHeader(authenticatedUser.jwt),
  );

  if (response.status !== 200)
    throwJsonRouteError(`Unable to load customer`, response.status);

  return parseDateTimeStrings(await response.json(), "joined");
};

export const useCustomerLoader = () => {
  return useLoaderData<Awaited<ReturnType<typeof customerLoader>>>();
};
