import { RouteObject } from "react-router-dom";
import { ErrorComponent } from "../../../components/ErrorComponent";
import { registrationsLoader } from "../loaders/registrationsLoader";
import { Registrations } from "./Registrations";
import { Registration } from "./Registration";
import { RegistrationErrorComponent } from "../components/RegistrationErrorComponent";
import { signUpViaPaypal } from "../actions/signUpViaPaypal";
import type { User } from "@admin-ui/providers/AuthProvider";
import { registrationLoader } from "../loaders/registrationLoader";

export const routes = (authenticatedUser: User): RouteObject[] => [
  {
    path: "registrations",
    element: <Registrations />,
    loader: () => registrationsLoader(authenticatedUser),
    errorElement: <ErrorComponent />,
    children: [
      {
        path: ":id",
        element: <Registration />,
        errorElement: <RegistrationErrorComponent />,
        loader: ({ params }) =>
          registrationLoader(authenticatedUser, params.id),
        // Will need to abstract this a bit better once more than on form/action is within the Registration.tsx component
        action: async ({ params }) =>
          signUpViaPaypal(authenticatedUser, params.id),
      },
    ],
  },
];
