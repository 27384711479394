import { ErrorComponent } from "@admin-ui/components/ErrorComponent";
import { SidebarLayout } from "@admin-ui/components/SidebarLayout";

export const RegistrationErrorComponent = () => {
  return (
    <SidebarLayout>
      <ErrorComponent />
    </SidebarLayout>
  );
};
