import { Modal } from "../../../components/Modal";
import { Fieldset, Form, SubmitButton } from "../../../../../../src/components";
import { FC } from "react";
import { useCustomerLoader } from "../loaders/customerLoader";
import { useNavigation, useSubmit } from "react-router-dom";
import { useChangeEmailActionData } from "../actions/changeEmailAction";
import { confirmEmailSchema } from "api";

type ConfirmEmailSchema = typeof confirmEmailSchema;

export const ChangeEmail: FC = () => {
  const customer = useCustomerLoader();
  const actionData = useChangeEmailActionData();
  const navigation = useNavigation();
  const submit = useSubmit();

  const actionError =
    actionData === undefined || actionData instanceof Response
      ? {}
      : actionData;

  return (
    <Modal className="w-[500px] p-2">
      <h1 className="text-lg font-bold">Change email</h1>
      {actionError.message && (
        <span className="text-red-800 font-semibold">
          {actionError.message}
        </span>
      )}
      <Form
        className="p-2"
        onSuccess={(data) => {
          submit(data, { method: "put", encType: "application/json" });
        }}
        schema={confirmEmailSchema}
        defaultValues={{ email: customer.userEmail }}
      >
        <Fieldset<ConfirmEmailSchema>
          displayName="Email"
          error={actionError.fields?.email}
          name="email"
          required
        />
        <Fieldset<ConfirmEmailSchema>
          displayName="Confirm Email"
          error={actionError.fields?.confirmEmail}
          name="confirmEmail"
          required
        />
        <SubmitButton loading={navigation.state === "submitting"}>
          Update
        </SubmitButton>
      </Form>
    </Modal>
  );
};
