import { z } from "zod";

export const getCustomerSchema = z.object({ id: z.string() });

export const confirmEmailSchema = z
  .object({
    email: z.string({ required_error: "Email is required" }).email(),
    confirmEmail: z
      .string({ required_error: "Confirmation email is required" })
      .email(),
  })
  .superRefine((values, ctx: z.RefinementCtx) => {
    if (values.email === values.confirmEmail) return true;

    ctx.addIssue({
      path: ["confirmEmail"],
      message: "Confirmation email does not match email",
      code: z.ZodIssueCode.custom,
    });
  });
