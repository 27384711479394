import { FC, PropsWithChildren } from "react";
import { useNavigate as useNavigateOriginal } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
  closeable?: boolean;
  expandable?: boolean;
  title: string;
  useNavigateOverride?: typeof useNavigateOriginal;
}

export const ModalWindow: FC<PropsWithChildren<Props>> = ({
  children,
  closeable = true,
  className,
  expandable,
  title,
  useNavigateOverride: useNavigate = useNavigateOriginal,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={twMerge(
        "bg-neutral-50 rounded-lg shadow",
        expandable ? "md:max-w-[72rem]" : "md:max-w-[36rem]",
        className,
      )}
      onClick={(event) => event.stopPropagation()}
    >
      <div className="flex bg-neutral-200 items-start justify-between px-6 py-4 border-b border-neutral-300 rounded-t">
        <h3 className="text-xl font-bold text-primary-800">{title}</h3>
        {closeable && (
          <button
            type="button"
            onClick={() => navigate("/")}
            className="text-neutral-400 bg-transparent hover:bg-neutral-200 hover:text-neutral-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        )}
      </div>
      <div className="p-6 space-y-6">{children}</div>
    </div>
  );
};
