import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DetailedHTMLProps, FC, ReactNode, ThHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

type Props = Omit<
  DetailedHTMLProps<
    ThHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  >,
  "onSelect"
> & {
  clickable?: boolean;
  label: ReactNode;
  sortDir?: "asc" | "desc" | false;
};

export const Th: FC<Props> = ({
  className,
  clickable = true,
  label,
  sortDir,
  ...props
}) => {
  return (
    <th
      className={twMerge(
        "p-0 px-4 py-3 font-semibold text-left border-t border-b border-r bg-neutral-100 text-neutral-900 border-neutral-300 group align-top",
        clickable && "cursor-pointer",
        (sortDir === "asc" || sortDir === "desc") && "bg-neutral-200",
        className,
      )}
      {...props}
    >
      <div className="flex flex-row h-full">
        <div className="flex flex-col grow">
          <div className="flex flex-row flex-grow">
            {typeof label === "string" ? (
              <span className="mb-2">{label}</span>
            ) : (
              label
            )}
            {sortDir !== undefined && sortDir !== false && (
              <FontAwesomeIcon
                className="ml-auto pl-2 text-neutral-600"
                icon={sortDir === "asc" ? faChevronDown : faChevronUp}
              />
            )}
          </div>
          {props.children}
        </div>
      </div>
    </th>
  );
};
